

import { defineComponent } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    watch: {
        active(value) {
            if (value) {
                this.getContacts();
            }
        },
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default: undefined,
        },
    },
    mounted() {
    },
    data() {
        return {
            isAddContactModal: false,
            newContact: {
                name: '',
                email: '',
                phone: '',
                category: 'order',
                notes: '',
            },
        };
    },
    methods: {
        addContact() {
            this.post(`clients/${this.$route.params.id}/contacts`, {
                ...this.newContact,
            })
                .then(() => {
                    this.getContacts();
                    this.isAddContactModal = false;
                });
        },
        deleteContact(id: string) {
            this.delete(`clients/${this.$route.params.id}/contacts/${id}`)
                .then(() => {
                    this.getContacts();
                });
        },
        getContacts() {
            this.get(`clients/${this.$route.params.id}/contacts`)
                .then(({ data }) => {
                    this.item.contacts = data.data;
                });
        },
        saveContact(data: any) {
            this.put(`clients/${this.$route.params.id}/contacts/${data.id}`, {
                ...data,
            })
                .then(() => {
                    this.getContacts();
                    this.isAddContactModal = false;
                });
        },
    },

});
