
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';
import General from '@/pages/clients/partials/General.vue';
import Contacts from '@/pages/clients/partials/Contacts.vue';

export default defineComponent({
    components: { Contacts, General },
    mixins: [Base],
    emits: ['reload'],
    data(): any {
        return {
            currentTab: 'general',
            item: undefined as any,
        };
    },
    mounted(): void {
        this.getItem(this.$route.params.id);
    },
    methods: {
        getItem(id: string): void {
            this.get(`clients/${id}`)
                .then(({ data }: any) => {
                    this.item = data.data;
                    (this.$refs.form as any).setValues(this.item);
                });
        },
        saveItem(): void {
            this.put(`clients/${this.$route.params.id}`, {
                ...this.item,
                platforms: this.item.platforms.map((p) => p.id),
                parent_id: this.item.parent ? this.item.parent.id : null,
            })
                .then(() => {
                    this.$router.push({ name: 'clients.list' });
                    this.$emit('reload');
                });
        },
    },
});
